import Vue from "vue";
import Vuex from "vuex";
import config from "@/config";
import axios from "@/plugins/axios";
import axiosInstance from "@/plugins/axios";

Vue.use(Vuex);

function initialStateLoadInEdit() {
  return {
    "id": null,
    "order_id": null,
    "dispatcher_id": null,
    "type_id": null,
    "wo": "",
    "status": "open",
    "notes": [],
    "created_at": "2022-11-21T15:51:08.000000Z",
    "created_by": null,
    "updated_at": "2022-11-21T15:51:08.000000Z",
    "updated_by": null,
    "deleted_at": null,
    "dispatcher": store.state.auth.user.counterparties[0],
    "load_carriers": [
      {
        "carrier_id": null,
        "equipment_type_id": null,
        "carrier_pay_type_id": null,
        "total_fee": null,
        "currency": null,
        "rate": null,
        "transport_number": null,
        "picks_drops": null,
        "fcs_value": null,
        "fcs_type": "amount",
        "other_charges": [],
        "carrier": null,
        "carrier_pay_type": null,
        "equipment_type": null,
        "load_carrier_shippers": [
          {
            "load_carrier_id": null,
            "shipper_id": null,
            "customs_broker_id": null,
            "location": null,
            "location_coordinates": null,
            "location_data": null,
            "date": null,
            "description": null,
            "type": null,
            "quantity": null,
            "weight": null,
            "cost": null,
            "shipping_notes": null,
            "po_numbers": null,
            "customs_broker": null,
            "shipper": null
          }
        ],
        "load_carrier_consignees": [
          {
            "load_carrier_id": null,
            "consignee_id": null,
            "location": null,
            "location_coordinates": null,
            "location_data": null,
            "date": null,
            "description": null,
            "type": null,
            "quantity": null,
            "weight": null,
            "value": null,
            "delivery_notes": null,
            "po_numbers": null,
            "consignee": null
          }
        ],
        "taxes": "Zero rate"
      }
    ],
    "load_finances": {
      "currency": null,
      "customer_id": null,
      "cargo_type": null,
      "rate": null,
      "pds": null,
      "fsc_value": null,
      "fsc_type": 'amount',
      "other_charges": [],
      "customer": null,
      "location": null,
      "taxes": "After taxes"
    },
    "load_sale_representants": null,
    "bol": []
  }
}
const store = new Vuex.Store({
  state: {
    showLoad: false,
    loadInEdit:  null,
    loadCompleted: null
  },
  getters: {
    role: state => {
      return store.state.auth.user.counterparties[0].roles
    }
  },
  mutations: {
    createLoad(state) {
      state.loadInEdit = initialStateLoadInEdit()
    },
    openLoad(state, item) {
      state.loadInEdit = JSON.parse(JSON.stringify(item));
     },
  },
  actions: {
    loadLoad (context, loadId) {
      return axios.get(config.apiUrl + '/api/load/' + loadId)
          .then(response => {
            context.commit('openLoad', response.data)
          })
    }
  },
  modules: {
    quickInvoices: {
      namespaced: true,
      state: {
        list: [],
        showQuickInvoices: false
      },
      actions: {
        getList (context, params) {
          return axios.get(config.apiUrl + '/api/invoice', {
            params: params
          })
          .then(response => {
            context.commit('setList', response.data)
          })
        }
      },
      mutations: {
        setList(state, payload) {
          state.list = payload
        },
        openModal(state, payload) {
          state.showQuickInvoices = payload
          if (payload === false) {
            state.list = []
          }
        }
      }
    },
    quickBills: {
      namespaced: true,
      state: {
        list: [],
        showQuickBills: false
      },
      actions: {
        getList (context, params) {
          return axios.get(config.apiUrl + '/api/bill', {
            params: params
          })
          .then(response => {
            context.commit('setList', response.data)
          })
        }
      },
      mutations: {
        setList(state, payload) {
          state.list = payload
        },
        openModal(state, payload) {
          state.showQuickBills = payload
          if (payload === false) {
            state.list = []
          }
        }
      }
    },
    customerHistory: {
      namespaced: true,
      state: {
        customerHistory: [],
        showCustomerHistory: false
      },
      actions: {
        customerHistory (context, params) {
          return axios.get(config.apiUrl + '/api/load', {
            params: params
          })
          .then(response => {
            context.commit('customerHistory', response.data)
          })
        }
      },
      mutations: {
        customerHistory(state, payload) {
          state.customerHistory = payload
          state.showCustomerHistory = true
        }
      }
    },
    carrierHistory: {
      namespaced: true,
      state: {
        carrierHistory: [],
        showCarrierHistory: false
      },
      actions: {
        carrierHistory (context, params) {
          return axios.get(config.apiUrl + '/api/load', {
            params: params
          })
          .then(response => {
            context.commit('carrierHistory', response.data)
          })
        }
      },
      mutations: {
        carrierHistory(state, payload) {
          state.carrierHistory = payload
          state.showCarrierHistory = true
        }
      }
    },
    taxes: {
      namespaced: true,
      state: {
        taxes: []
      },
      actions: {
        loadTaxes (context) {
          return axiosInstance.get(config.apiUrl + '/api/taxes')
          .then(response => {
            context.commit('setTaxes', response.data)
          })
        }
      },
      mutations: {
        setTaxes(state, payload) {
          state.taxes = payload
        }
      }
    },
    reports: {
      namespaced: true,
      state: {
        userSalaryOverview: []
      },
      mutations: {
        addUserSalaryOverview(state, id) {
          state.userSalaryOverview.push(id)
        },
        removeUserSalaryOverview(state, id) {
          state.userSalaryOverview.splice(state.userSalaryOverview.indexOf(id), 1)
        }
      }
    },
    users: {
      namespaced: true,
      state: {
        data: {
          data: [],
          total: null
        },
        inEdit: null
      },
      mutations: {
        inEdit (state, item) {
          state.inEdit = item
        },
        newInEdit (state, data) {
          data = data || {};
          state.inEdit = {
            "id": null,
            "company_id": 1,
            "name": "",
            "status": "active",
            "default_currency_id": "USD",
            "roles": [],
            "user": {
              "name": "",
              "email": "",
              "password": ""
            },
            "counterparty_addresses": [],
            "counterparty_contacts": [],
            "company": {
              "id": 1,
            },
            "counterparty_insurances": [],
            "carrier_data": null,
            "customer_data": null,
            "teams": [],
            ...data
          }
        }
      },
      actions: {
        getList (context, params) {
          return axios.get(config.apiUrl + '/api/counterparty', { params: params }).then((response) => {
            context.state.data = response.data
          });
        },
        freshInEdit (context, id) {
          return axios.get(config.apiUrl + '/api/counterparty/' + id).then((response) => {
            context.commit('inEdit', response.data)
          });
        }
      }
    },
    counterparty: {
      namespaced: true,
      actions: {
        save (context, data) {
          let method = 'post';
          let url = config.apiUrl + '/api/counterparty';
          if (data['id'] != null) {
            url += '/' + data['id'].toString();
            method = 'patch';
          } else {
            url += '/';
            method = 'post';
          }
          return axios({
            method: method,
            url: url,
            data: data
          });
        }
      }
    },
    teams: {
      namespaced: true,
      state: {
        list: [],
        inEdit: null
      },
      mutations: {
        inEdit (state, item) {
          state.inEdit = item
        },
        newInEdit (state) {
          state.inEdit = {
            id: null,
            name: ''
          }
        }
      },
      actions: {
        getList (context) {
          return axios.get(config.apiUrl + '/api/team').then((response) => {
            context.state.list = response.data
          });
        },
        save (context, data) {
          let method = 'post';
          let url = config.apiUrl + '/api/team';
          if (data['id'] !== null) {
            url += '/' + data['id'].toString();
            method = 'patch';
          }
          return axios({
            method: method,
            url: url,
            data: data
          });
        },
        remove (context, data) {
          return axios({
            method: 'delete',
            url: config.apiUrl + '/api/team/' + data.id
          });
        }
      }
    },
    auth: {
      namespaced: true,
      state: {
        user: null,
        token: null,
      },
      getters: {
        isUserLogged (state) {
          return state.user !== null && state.token !== null
        }
      }
    },
    payment: {
      namespaced: true,
      state: {
        payInEdit: null,
      },
      mutations: {
        openPayment (state, params) {
          state.payInEdit = Object.assign({
            type: null,
            itemIds: [],
            counterpartyId: null,
          }, params);
        },
        closePayment (state) {
          state.payInEdit = null;
        }
      },
    },
    salary: {
      namespaced: true,
      state: {
        reportInEdit: null,
      },
      actions: {},
      mutations: {
        openReport(state, report) {
          state.reportInEdit = report
        },
        closeReport (state) {
          state.reportInEdit = null
        }
      }
    },
  },
});
export default store
