<template>
  <div class="mt-2">
    <v-row align="center" dense class=" px-3">
      <v-col md="3">
        <blocking-layover :blocking-condition="restrictedEdit">
          <CounterpartySelect :clearable="true" @input="onCarrierChanged" v-model="carrier.carrier" :roles="['carrier']" @clear="carrier.carrier = null; carrier.carrier_id = null;" />
        </blocking-layover>
      </v-col>
      <v-col md="2">
        <EquipmentTypeSelect v-model="carrier.equipment_type"></EquipmentTypeSelect>
      </v-col>
      <v-col md="1">
        <CarrierFee :carrier="carrier"></CarrierFee>
      </v-col>
      <v-col :md="carrier.carrier && load.id && load.load_carriers[0]?.load_carrier_consignees[0]?.consignee && load.load_carriers[0]?.load_carrier_shippers[0]?.shipper ? '2' : '3'">
        <v-select class="mini" v-model="carrier.currency" hide-details="auto" :items="currency" label="Currency"
          outlined dense clearable>
        </v-select>
      </v-col>
      <v-col md="2">
        <v-text-field v-model="carrier.transport_number" label="Truck # / Trailer #" class="mini" hide-details="auto" outlined dense clearable></v-text-field>
      </v-col>
      <v-col md="2" v-if="carrier.carrier && load.id && load.load_carriers[0]?.load_carrier_consignees[0]?.consignee && load.load_carriers[0]?.load_carrier_shippers[0]?.shipper">
        <div class="d-flex">
            <v-btn style="pointer-events: all;" small dark color="green"  @click="$emit('openRC')">
              <v-icon color="white" small class="mr-3">
                mdi-text-box-check-outline</v-icon>
              Rate Con
            </v-btn>
            <v-btn @click="openLoadGps" small dark color="dark" class="ml-3" >
              <v-icon color="white" small class="mr-3">mdi-map-marker-outline</v-icon>
              Tracking
            </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <Shippers :shippers="carrier.load_carrier_shippers" @duplicate-fields-changed="onDuplicateFieldsChange" />
    <v-divider></v-divider>
    <Consignee :consignees="carrier.load_carrier_consignees" />

    <v-snackbar
      v-model="successInsuranceRenew"
      :timeout="2000"
    >
      Carrier insurance data were updated! You can check it out on carrier profile.

      <template v-slot:action="{ attrs }">
        <v-btn
          color="success"
          text
          v-bind="attrs"
          @click="successInsuranceRenew = null"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="failInsuranceRenew"
      :timeout="5000"
      top
    >
      <p>We could not reniew carrier insurances, please update insurances information </p>
<!--    TODO: Refacor css  -->
      <div class="bi-exclamation-triangle pulse-text" style="color: #e70b22;">
        <b class="pl-4 mt-1 " style="color: #e70b22; text-shadow: 0px 0px 1px black">URGENTLY!</b>
      </div>
    </v-snackbar>
    <v-dialog :value="loadGps"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
              @keydown.esc="closeGps"
              @click:outside="closeGps"
              @close="closeGps">
      <v-card>
        <v-toolbar
            dark
            color="primary"
        >
          <v-toolbar-title>Load #{{ carrier.load_id }} tracking number</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                icon
                dark
                @click="closeGps"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row no-gutters>
          <v-col>
            <v-card-text class="mt-3">
              <v-row class="mb-1">
                <v-col class="col-4">
                  <v-otp-input
                      length="8"
                      :value="token ? token.token : 'xxxxxxxx'"
                      readonly
                  />
                  <v-select x-small @change="updateCarrierStatus" label="Status" hide-details outlined v-model="carrier.status" :items="['not_started', 'in_progress', 'finished']">
                    <template v-slot:selection="{ item }">
                      {{ item.toUpperCase().replace('_', ' ') }}
                    </template>
                    <template v-slot:item="{ item }">
                      {{ item.toUpperCase().replace('_', ' ') }}
                    </template>
                  </v-select>
                </v-col>
                <v-col class="col-6">
                  <v-row>
                    <v-col md="3">
                      <template v-if="token">
                        <p>Client tracking:</p>
                        <v-btn target="_blank" :href="'http://75.119.152.119:8182/tracking/' + token.tracking_id" small color="primary">
                          OPEN
                        </v-btn>
                      </template>
                    </v-col>
                    <v-col>
                      <div class="float-right">
                        <template v-if="token == null" class="pt-4">
                          <v-btn @click="generateToken" :loading="tokenLoading" color="primary">
                            GENERATE
                          </v-btn>
                        </template>
                        <template v-else>
                          <p>Send tracking number to Carrier:</p>
                          <v-spacer></v-spacer>
                          <v-btn color="success" small dark @click="copyToClipboard(token)">
                            <v-icon small>mdi-content-copy</v-icon> Copy
                          </v-btn>
                          <v-btn color="primary" small class="ml-3" dark @click="sendToCarrier('sms')">
                            Via SMS
                          </v-btn>
                          <v-btn color="primary" small dark class="ml-3" @click="sendToCarrier('email')">
                            Via Email
                          </v-btn>
                          <v-btn v-if="token !== null" small class="ml-3"
                                 @click="fetchCarrierLocation" color="primary" dark>
                            GET CARRIER LOCATION
                          </v-btn>
                          <v-btn title="Share route history"
                                 :color="'#d0700f'" small dark class="ml-3"
                                 @click="shareRouteHistory">
                            <v-icon small>
                              mdi-export-variant
                            </v-icon>
                          </v-btn>
                        </template>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <div style="width:300px">
              </div>
              <v-row>
                <v-col style="max-width: 28%">
                  <RouteHistory :history="locationMarkers" />
                </v-col>
                <v-col style="max-width: 72%">
                  <RouteMap
                      :traking_id="tracking_id"
                      :currentLocation="carrierLocation"
                      :socket_connection="socket_connection"
                      :carrier="carrier" :load="load" @sendMarkers="updateMarkers" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
          <v-col v-if="loadGps && token?.tracking_id && token?.fcm_token" class="chat-column" cols="2">
            <CarrierChat
                :dispatcher-id="this.$store.state.auth.user.counterparties[0].id"
                :display-name-dispatcher="this.$store.state.auth.user.counterparties[0].name"
                :carrier-id="carrier.carrier_id"
                :fcm-token-carrier="token.fcm_token"
                :display-name-carrier="carrier.carrier.name"
                :load-id="load.id"
                :load_carrier_id="carrier.id"
            />
          </v-col>

        </v-row>

        <v-card-actions>
        </v-card-actions>
      </v-card>
    <v-snackbar
      v-model="copied"
      :timeout="2000"
      top
    >
      {{ copiedText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="success"
          text
          v-bind="attrs"
          @click="copied = null"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    </v-dialog>
    <v-snackbar
      v-model="errorSend"
      :timeout="2000"
    >
      {{ errorSendText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="errorSend = null"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Shippers from '../shippers/List.vue';
import Consignee from '../consignees/List.vue';
import CounterpartySelect from "@/components/counterparty/CounterpartySelect";
import EquipmentTypeSelect from "@/components/loads/carriers/EquipmentTypeSelect";
import BlockingLayover from '@/components/layout/BlockingLayover.vue';
import CarrierFee from "@/components/loads/carriers/CarrierFee.vue";
import loadLayoverMixin from '@/mixins/loadLayoverMixin';
import axios from "@/plugins/axios";
import config from "@/config";
import CarrierChat from "@/components/loads/carriers/CarrierChat.vue";
import RouteHistory from "@/components/map/RouteHistory.vue";
import RouteMap from "@/components/map/RouteMap.vue";

let tokenRequestInterval;

export default {
  mixins: [loadLayoverMixin],
  props: {
    carrier: {
      type: Object,
      required: true,
    },
    load: {
      type: Object,
      required: false
    }
  },
  components: {
    RouteMap,
    RouteHistory,
    CarrierFee,
    EquipmentTypeSelect,
    CounterpartySelect,
    BlockingLayover,
    Shippers,
    Consignee,
    CarrierChat
  },
  computed: {
    restrictedEdit() {
      if(this.load.status === 'completed' && this.$store.getters.role.includes('customer_support')) {
        return false
      }
      return this.restrictLoadEdit(this.load)
    },
    loadInEdit() {
      return this.$store.state.loadInEdit;
    },
  },
  data() {
    return {
      equipment_type: [1, 2, 3, 4],
      currency: ['USD', 'CAD'],
      loadGps: false,
      errorSend: null,
      errorSendText: null,
      successInsuranceRenew: false,
      failInsuranceRenew: false,
      copied: false,
      copiedText: null,
      token: null,
      tracking_id: '',
      tokenLoading: false,
      carrierLocation: null,
      locationInfo: {},
      locationMarkers: [],
      socket_connection: false,
      locationRequested: false
    }
  },
  mounted() {
    this.locationRequested = false
  },
  methods: {
    closeGps() {
      this.loadGps = false
      this.socket_connection = false
      if (tokenRequestInterval !== undefined)
        clearInterval(tokenRequestInterval)
    },
    shareRouteHistory() {
      const url = `${window.location.origin}/clientroutehistory/${this.tracking_id}&${this.load.id}`;

      window.navigator.clipboard.writeText(url)
          .then(() => {
            this.copiedText = 'Route history link COPIED'
            this.copied = true
          }).catch(err => {console.error('Failed to copy text: ', err);});
    },
    updateMarkers(markers) {
      this.locationMarkers = markers
    },
    sendToCarrier(v) {
      this.errorSend = true
      if (v === 'sms') {
        this.errorSendText = 'SMS provider error'
      } else {
        this.errorSendText = 'Mail not connected'
      }
    },
    copyToClipboard(value) {
      const clipboardText = `
Thanks for hauling with Transimex !
Please follow the instructions below, and help yourself with smooth operations!

1) Please download the Tracking App
Apple: https://apps.apple.com/md/app/transimex-carrier/id6478484369
Or Android: https://play.google.com/store/apps/details?id=com.transimex.carrier&hl=en&gl=US
2) Please allow notifications.
3) Please make sure to accept Terms and Conditions by pressing on Continue.
4) Always While Using App to your location.

Please use One-Time Code to start Tracking:
${value.token}
`
      this.copiedText = 'Tracking number copied!'
      navigator.clipboard.writeText(clipboardText.trim())
      this.copied = true
    },
    async openLoadGps () {
      await axios.get(config.apiUrl + `/api/carrier-token/${this.load.id}/${this.carrier.carrier.id}`)
        .then((response) => {
            this.token = response.data
            this.tracking_id = this.token.tracking_id
            if (this.tracking_id) {
              this.socket_connection = true
            }
        })
        .catch(() => {
            this.token = null
        })
      this.loadGps = true
    },
    fcmTokenChecker() {
      const time = 5000;
      const url = config.apiUrl + '/api/tracking/' + this.token.tracking_id + '/fcm_token'
      tokenRequestInterval = setInterval(() => {
        axios.get(url).then((response) => {
              if (response.data.token) {
                this.token.fcm_token = response.data.token
                clearInterval(tokenRequestInterval)
              }})
      }, time);
    },
    generateToken () {
        this.tokenLoading = true
        const url = `${config.apiUrl}/api/carrier-token/${this.load.id}/${this.carrier.carrier.id}`
        axios.post(url).then((response) => {
            this.token = response.data
            this.tokenLoading = false
            this.fcmTokenChecker()
            this.tracking_id = this.token.tracking_id
            this.socket_connection = true
        })
    },
    fetchCarrierLocation () {
        axios.get(config.trackingApi + `/get-user-location/${this.token.tracking_id}`)
          .then((response) => {
              this.carrierLocation = response.data
          })
          .catch(() => {
              this.errorSend = true
              this.errorSendText = 'Carrier location not found'
          })
          axios.get(config.apiUrl + '/api/carrier-token/' + this.token.id + '/request-location')
            .then((response) => {
              this.locationRequested = true
            })
            .catch((error) => {
              alert(error.response.data.message)
            })
    },
    updateCarrierStatus (value) {
        axios.patch(config.apiUrl + `/api/carrier-status/${this.load.id}/${this.carrier.carrier.id}`, {status: value})
          .then(() => {
              this.errorSend = true
              this.errorSendText = 'Carrier status updated'
          })
    },
    onDuplicateFieldsChange(values, oldValues, index) {
      for (let prop in values) {
        if (
          typeof this.carrier.load_carrier_consignees[index] !== "undefined"
          &&
          (
            !(this.carrier.load_carrier_consignees[index][prop])
            ||
            this.carrier.load_carrier_consignees[index][prop] === oldValues[prop]
          )
        ) {
          this.carrier.load_carrier_consignees[index][prop] = values[prop]
        }
      }
    },
    onCarrierChanged (carrier, eventType) {
      if (carrier && carrier.carrier_data && carrier.carrier_data.default_currency) {
        this.carrier.currency = carrier.carrier_data.default_currency
      } else {
        this.carrier.currency = 'CAD'
      }
      if (eventType === 'change') {
        axios.get(config.apiUrl + '/api/counterparty/' + carrier.id + '/insurances')
          .then((response) => {
            if(response.data.expired.length > 0) {
              this.errorSend = true
              /*TODO: Put text into dictionary*/
              this.errorSendText = 'Carrier have expired insurance, please check mandatory!'
            }
            if (response.data.expired.length || response.data.expired_soon.length) {
              axios.get(config.apiUrl + '/api/counterparty/' + carrier.id + '/insurances_renew').then((newResponse) => {
                if (newResponse.data.expired.length > 0) {
                    alert(carrier.name + ' - insurance expired');
                } else if (newResponse.data.expired_soon.length > 0) {
                    alert(carrier.name + ' - insurance expires in 14 days');
                } else {
                  this.successInsuranceRenew = true
                }
              }).catch(() => this.failInsuranceRenew = true)
            }
          })
      }
    },
  },

}

</script>

<style lang="scss">
/* Styling the text */
.pulse-text {
  font-size: 14px;
  color: #d22630;
  font-weight: bold;
  text-align: center;
  animation: pulse 2s infinite; /* Applying the animation */
}

/* Pulse animation */
@keyframes pulse {
  0% {
    transform: scale(1); /* Normal size */
  }
  50% {
    transform: scale(1.2); /* Enlarged size */
  }
  100% {
    transform: scale(1); /* Back to normal size */
  }
}
</style>