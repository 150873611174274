<template>

  <v-container fluid class="carrier_new_wrapper">
    <v-dialog v-model="show_code_modal" persistent :fullscreen="true">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-container>
            <div class="d-flex logo">
              <img src="@/assets/logo.png" alt="Logo" />
              <span>TRANSIMEX</span>
            </div>
            <div style="margin-top: 30%;">
              <h4 class="text-center">Please insert one time code that we sent to your email:</h4>
              <v-row style="max-width: 400px; margin: 0 auto;">
                <v-col>
                  <v-text-field
                      outlined dense clearable
                      label="One time code"
                      v-model="one_time_code"
                      :rules="[validationRules.required]"
                  />
                </v-col>
                <v-col>
                  <v-btn
                      @click="checkOneTimeCode"
                      medium color="primary" class="w-50">
                    SUBMIT
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-row justify="center" class="container m-auto">
      <v-col cols="12" md="12" sm="6">

        <div class="header">
          <div class="logo" style="margin: 25px auto; width: max-content;">
            <v-btn normal text color="white">
              <img src="@/assets/logo.png" alt="Logo"/>
              <span>TRANSIMEX</span>
            </v-btn>
          </div>
        </div>

        <div v-if="isFormSubmitted" class="text-center my-5 py-5">

          <v-icon size="64" class="text-success mb-5">mdi-check-circle-outline</v-icon>
          <h3 class="text-success">Thanks for submitting the form!</h3>

        </div>

        <v-form v-else ref="newCarrierForm" v-model="isFormValid">
          <v-card>

            <v-card-title class="primary white--text py-1 d-flex justify-center">
              <h2 class="text-center">Become a Carrier</h2>
            </v-card-title>

            <v-card-text>

              <div class="general_info mt-4">
                <h3 class="ms-5">General Info:</h3>

                <v-row v-if="!have_mc">
                  <v-col style="height: 60px">
                    <v-text-field
                        outlined dense clearable
                        label="CARRIER NAME"
                        v-model="formData.general_info.carrier_name"
                        :rules="[validationRules.required]"
                    />
                  </v-col>

                  <v-col style="height: 60px">
                    <v-text-field
                        outlined dense clearable
                        label="CONTACT NAME"
                        v-model="formData.general_info.contact_name"
                        :rules="[validationRules.validName]"
                    />
                  </v-col>
                  <v-col v-if="!have_mc" style="height: 60px">
                    <v-btn @click="haveMC">Have MC</v-btn>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col v-if="!have_mc" style="height: 60px">
                    <LocationAutocomplete
                        :countries="allowedCountries"
                        :must-have-post-code="true"
                        :hide-input-errors="false"
                        label="BILLING ADDRESS"
                        :id="'cae-' + 1"
                        :entity="{location: ''}"
                        @selected="updateAddress(addressBilling, ...arguments)"
                        :validation-rules="[validationRules.validAddress]"
                    ></LocationAutocomplete>
                  </v-col>
                  <v-col v-if="!have_mc" style="height: 60px">
                    <v-text-field
                        outlined dense clearable
                        label="PHONE NUMBER"
                        v-model="formData.general_info.phone"
                        maxlength="10"
                        :rules="[validationRules.validPhone]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col style="height: 60px">
                    <v-text-field
                        ref="form_email"
                        :style="have_mc ? 'maxWidth: 80%' : ''"
                        outlined dense clearable
                        :disabled="disabled_fields"
                        label="BILLING EMAIL"
                        v-model="formData.general_info.email"
                        :rules="[validationRules.validEmail]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col v-if="have_mc" style="height: 60px">
                    <v-btn
                        :disabled="disabled_fields"
                        @click="dontHaveMC">Don't have MC</v-btn>
                  </v-col>
                </v-row>

                <v-row v-if="!localInCanada && have_mc">
                  <v-col>
                    <v-text-field
                        class="float-left"
                        style="width: 80%;"
                        outlined dense clearable
                        label="MC#"
                        :disabled="disabled_fields"
                        v-model="formData.general_info.mc"
                        maxlength="7"
                        :rules="[validationRules.validMc]"
                    >
                    </v-text-field>
                    <v-btn
                        :loading="isLoading"
                        @click="searchByMC"
                        title="Search by MC#"
                        :disabled="show_sw_btn ? false : true"
                        :class="show_sw_btn ? 'pulsating' : ''"
                        small class="border float-right mt-1">
                      <div class="d-flex align-items-center">
                        <span class="text-default font-weight-bold subtitle-1">S</span>
                        <span class="text-success font-weight-bold subtitle-1">W</span>
                      </div>
                      <v-icon v-if="!sw_status" small> mdi-magnify</v-icon>
                      <v-icon v-else color="success" small>mdi-check-circle</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-text-field
                        outlined dense clearable
                        label="DOT#"
                        :disabled="disabled_fields"
                        v-model="formData.general_info.dot"
                        maxlength="8"
                        :rules="[validationRules.validDot]"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-sheet :style="localInCanada ? 'marginTop:25px' : 'marginTop:0px'"
                         class="text-center">
                  <v-btn
                      :disabled="disabled_fields"
                      :style="!have_mc ? 'marginTop:25px' : 'marginTop:0px'"
                      color="primary" class="w-50" @click="handleLocalInCanada">
                    <span v-text="localInCanada ? 'NOT LOCAL IN CANADA?' : 'LOCAL IN CANADA?'"></span>
                  </v-btn>
                </v-sheet>

                <!--                TODO: Fix Local in Canada location of data-->
                <v-text-field
                    v-if="localInCanada"
                    outlined dense clearable
                    v-model="formData.carrier_data.cbi_value"
                    class="mt-4"
                    label="BUSINESS ID# (CANADA CARRIERS ONLY*)"
                    :rules="[validationRules.required]"

                >
                </v-text-field>

              </div>

              <div v-show="form_step > 1" class="authority mt-4">
                <h3 class="ms-5">Authority:</h3>
                <v-file-input
                    :accept="acceptedExtensions"
                    v-model="files.mc_nir_cvor"
                    outlined label="AUTHORITY FILE"
                    prepend-icon=""
                    append-icon="mdi-paperclip"
                    :rules="[validationRules.required, validationRules.validFileFormat]"
                >
                </v-file-input>
              </div>

              <div v-if="form_step > 2" class="payment mt-4">
                <h3 class="ms-5">Payment info:</h3>

                <v-row>
                  <v-col>
                    <v-autocomplete
                        :items="factoringCompanies"
                        class="mini"
                        v-model="formData.payment.company"
                        item-text="name"
                        item-value="id"
                        label="Factoring company"
                        persistent-hint outlined dense clearable
                    >
                    </v-autocomplete>
                    <v-text-field
                        outlined dense clearable
                        label="BANK NAME"
                        v-model="formData.payment.bank_name"
                        :rules="[validationRules.required]"
                    >
                    </v-text-field>
                    <v-text-field
                        outlined dense clearable
                        label="INSTITUTION#"
                        v-model="formData.payment.inst_nr"
                        :rules="[validationRules.required, validationRules.validBankInstitutionNumber]"
                        maxlength="4"
                    >
                    </v-text-field>
                    <v-text-field
                        outlined dense clearable
                        label="ACCOUNT#"
                        v-model="formData.payment.acc_nr"
                        :rules="[validationRules.required, validationRules.validBankAccountNumber]"
                        maxlength="9"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                        v-if="!localInCanada"
                        outlined dense clearable
                        label="ABA# (US CARRIERS ONLY)"
                        v-model="formData.payment.aba_nr"
                        :rules="[validationRules.required, validationRules.validAbaNumber]"
                        maxlength="9"
                    >
                    </v-text-field>
                    <v-text-field
                        type="date"
                        onfocus="this.showPicker()"
                        outlined dense
                        label="DATE"
                        v-model="formData.payment.reg_date"
                        :rules="[validationRules.required]"
                    >
                    </v-text-field>
                    <v-text-field
                        outlined dense clearable
                        label="TRANSIT#"
                        v-model="formData.payment.transit_nr"
                        :rules="[validationRules.required, validationRules.validBankTransitNumber]"
                        maxlength="5"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <CounterpartySignature
                    @signed="generateSignatureFile($event)"
                    width="450px"
                    height="208px"
                    @clear="files.signature = null"
                    :validate-signature="validateSignature"
                />
                <v-file-input
                    :accept="acceptedExtensions"
                    v-model="files.bank_details"
                    outlined label="PAYMENT FILE"
                    prepend-icon=""
                    append-icon="mdi-paperclip"
                    :rules="[validationRules.validFileFormat, validationRules.required]"
                >
                </v-file-input>
              </div>

              <div v-show="form_step > 3" class="insurance mt-4">
                <h3 class="ms-5">Insurance:</h3>
                <v-row class="mt-0 pt-0" v-for="item in formData.insurances.items">
                  <v-col>
                    <v-text-field
                        outlined dense clearable
                        :label="item.type"
                        v-model="item.policy_number"
                        :rules="[validationRules.required]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                        type="date"
                        onfocus="this.showPicker()"
                        outlined dense
                        label="DATE"
                        v-model="item.policy_expiration"
                        :rules="[validationRules.required]"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-file-input
                    :accept="acceptedExtensions"
                    v-model="files.insurance_certificate"
                    outlined label="INSURANCE FILE"
                    prepend-icon=""
                    append-icon="mdi-paperclip"
                    :rules="[validationRules.required, validationRules.validFileFormat]">
                </v-file-input>
              </div>

              <div v-show="form_step > 4"
                   class="w89_form mt-4">
                <h3 class="ms-5">W8/9 FORM</h3>
                <v-file-input
                    :accept="acceptedExtensions"
                    v-model="files.w8_9"
                    outlined label="W 8/9 FILE"
                    prepend-icon=""
                    append-icon="mdi-paperclip"
                    :rules="[validationRules.required, validationRules.validFileFormat]"
                >
                </v-file-input>
              </div>

              <div v-if="form_step > 4"
                   class="my-5 d-flex align-items-center justify-content-center">
                <v-checkbox v-model="isTermsAgreed" :rules="[validationRules.required]">
                  <template v-slot:label>
                    <div>
                      I have read and agreed to the
                      <v-tooltip>
                        <template v-slot:activator="{ props }">
                          <a href="/Terms-and-Conditions.pdf" target="_blank" v-bind="props" @click.stop>
                            Terms and Conditions
                          </a>
                        </template>
                      </v-tooltip>
                    </div>
                  </template>
                </v-checkbox>
              </div>

              <div v-if="form_step > 4" class="d-flex justify-content-center mb-3">
                <v-btn :loading="isLoading" @click="addNewCarrier" x-large color="primary" class="w-50">
                  SUBMIT
                </v-btn>
              </div>

            </v-card-text>

          </v-card>
        </v-form>

      </v-col>
    </v-row>

    <v-snackbar
        v-model="carrierNewError"
        :timeout="5000"
        color="red accent-2"
        top
    >
      <b>Errors:</b>
      <div v-html="carrierNewErrorText"></div>
    </v-snackbar>

    <v-snackbar
        v-model="email_sent"
        :timeout="5000"
        color="green"
        bottom
    >
      <div v-html="email_sent_text"></div>
    </v-snackbar>

  </v-container>

</template>

<script>

import axios from "axios";
import config from "@/config";
import LocationAutocomplete from "@/components/location/LocationAutocomplete.vue";
import CounterpartySignature from "@/components/counterparty/CounterpartySignature.vue";
import validation from "@/helpers/validation";

export default {
  name: "CarrierNew",
  components: {CounterpartySignature, LocationAutocomplete},
  data() {
    return {
      email_sent: false,
      email_sent_text: '',
      form_step: 1,
      have_mc: true,
      disabled_fields: false,
      show_sw_btn: false,
      show_code_modal: false,
      one_time_code: '',
      sw_status: false,
      allowedCountries: ['ca', 'us'],
      acceptedExtensions: '.pdf, .doc, .docx, .jpg, .jpeg, .png',
      agreementFile: null,
      isLoading: false,
      carrierNewErrorText: null,
      carrierNewError: false,
      validateSignature: false,
      isSigned: false,
      factoringCompanies: [],
      isFormSubmitted: false,
      isFormValid: false,
      addressEntityW89: {
        location: ''
      },
      addressBilling: {},
      addressW89: {},
      validationRules: {
        required: value => !!value || 'Required',
        validAddress: value => !!value || 'Please provide an address that has postal code',
        validName: value => (value && /^[a-z ,.'-]+$/i.test(value)) || 'Invalid name',
        validEmail: value => (value && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,24}$/.test(value)) || 'E-mail must be valid',
        validPhone: value => (value && (value.length === 10 && Number.isInteger(Number(value)))) || 'Phone number must contain 10 digits',
        validMc: value => (value && (value.length >= 5 && value.length <= 7) && Number.isInteger(Number(value))) || 'Must be between 5 and 7 digits',
        validDot: value => (value && (value.length >= 6 && value.length <= 8) && Number.isInteger(Number(value))) || 'Must be between 6 and 8 digits',
        validBankInstitutionNumber: value => (value && value.length === 4 && Number.isInteger(Number(value))) || 'Must contain 4 digits',
        validBankAccountNumber: value => (value && (value.length === 7 || value.length === 9) && Number.isInteger(Number(value))) || 'Must contain 7 (USA) or 9 digits (Canada)',
        validBankTransitNumber: value => (value && value.length === 5 && Number.isInteger(Number(value))) || 'Must contain 5 digits',
        validAbaNumber: value => (value && value.length === 9 && Number.isInteger(Number(value))) || 'Must contain 9 digits',
        validFileFormat: value => {
          const error_text = 'Invalid file format. Accepted formats: PDF, DOC, DOCX, JPG, JPEG, PNG'
          if (!value || value === null) return error_text
          const allowedExtensions = ['pdf', 'doc', 'docx', 'jpg', 'jpeg', 'png'];
          const fileExtension = value.name.split('.').pop().toLowerCase();
          return allowedExtensions.includes(fileExtension) || error_text
        },
        validRegNr: value => (value && value.length === 4 && Number.isInteger(Number(value))) || 'Must contain 4 digits',
      },
      localInCanada: false,
      formData: {
        general_info: {
          dot: "",
          email: "",
          mc: "",
          carrier_name: "",
          contact_name: "",
          phone: "",
          second_email: ""
        },
        authority: {
          registration: '',
          expiry_date: '',
          file: null,
        },
        payment: {
          file: null,
          company: '',
          bank_name: '',
          inst_nr: '',
          acc_nr: '',
          aba_nr: '',
          reg_date: '',
          transit_nr: '',
          sign: null,
        },
        insurances: {
          file: null,
          items: [
            {
              type: "auto",
              contact_name: null,
              policy_expiration: null,
            },
            {
              type: "cargo",
              contact_name: null,
              policy_expiration: null,
            },
            {
              type: "liability",
              contact_name: null,
              policy_expiration: null,
            },
          ]
        },
        roles: [
          "carrier"
        ],
        counterparty_addresses: [],
        counterparty_insurances: [
          {
            type: "auto",
            contact_name: null,
          },
          {
            type: "cargo",
            contact_name: null,
          },
          {
            type: "liability",
            contact_name: null,
          },
        ],
        counterparty_bank_account: {},
        carrier_data: {
          mc_ff_type: 'mc',
          docs: {
            authority: {},
            w8_9_form: {},
          }
        },
      },
      isTermsAgreed: false,
      files: {
        mc_nir_cvor: null,
        bank_details: null,
        insurance_certificate: null,
        w8_9: null,
        signature: null,
      },
      otc_email: '',
    }
  },
  mounted() {
    this.$refs.newCarrierForm.inputs[0].focus()
    axios.get(config.apiUrl + '/api/counterparty/factoring-companies')
        .then((response) => {
          this.factoringCompanies = response.data
        })
    fetch('/Terms-and-Conditions.pdf')
        .then(response => response.blob())
        .then(blob => {
          this.agreementFile = new File([blob], 'Terms-and-Conditions.pdf', {type: 'application/pdf'})
        })
        .catch(error => {
          console.error('Error loading PDF file:', error);
        })
  },
  computed: {
    mcAndEmail() {
      return {
        mc: this.formData.general_info.mc,
        email: this.formData.general_info.email
      };
    },
    authority() {
      return {
        file: this.files.mc_nir_cvor
      }
    },
    payment() {
      this.formData.payment.file = this.files.bank_details
      this.formData.payment.sign = this.files.signature
      return { ...this.formData.payment }
    },
    insurance() {
      this.formData.insurances.file = this.files.insurance_certificate
      return { ...this.formData.insurances }
    }
  },
  watch: {
    mcAndEmail(newVal) {
      const mc = newVal.mc
      const email = newVal.email
      if (this.validationRules.validMc(mc) === true
          && this.validationRules.validEmail(email) === true) {
        this.show_sw_btn = true
      } else {
        this.show_sw_btn = false
      }
    },
    authority(newVal) {
      if (this.form_step > 1 && !this.have_mc)
        if(this.validationRules.validFileFormat(newVal.file)
            && newVal.file !== null)
          this.form_step = 3
    },
    payment(newVal) {
      if (this.form_step === 3 && !this.have_mc) {
        if( newVal.file !== null &&
            newVal.sign instanceof File &&
            newVal.bank_name !== '' &&
            this.validationRules.validBankInstitutionNumber(newVal.inst_nr) &&
            this.validationRules.validBankAccountNumber(newVal.acc_nr) &&
            this.validationRules.validBankTransitNumber(newVal.transit_nr) &&
            validation.isValidDate(newVal.reg_date) &&
            this.validationRules.validAbaNumber(newVal.aba_nr)
        ) {
          this.form_step = 4
        } else {
          this.$refs.newCarrierForm.validate()
        }
      }
    },
    insurance(newVal) {
      if (this.form_step > 3 && !this.have_mc) {
        if(this.validationRules.validFileFormat(newVal.file)
            && newVal.file !== null)
          this.form_step = 5
      }
    }
  },
  methods: {
    prepareFormDataForSave(formData) {
      if (this.otc_email !== '') {
        formData.general_info.second_email = this.otc_email
      }
      formData.general_info.contact_name = this.formData.general_info.contact_name.trim()

      const response = {
        name: formData.general_info.carrier_name,
        counterparty_contacts: formData.general_info,
        counterparty_addresses: formData.counterparty_addresses,
        counterparty_bank_accounts: formData.payment,
        counterparty_insurances: formData.insurances
      }

      return response;
    },
    dontHaveMC() {
      this.$refs.newCarrierForm.inputs[0].focus()
      this.formData.general_info.contact_name = ' '
      this.have_mc = false
      this.form_step = 2
    },
    haveMC() {
      this.have_mc = true
      this.form_step = 1
    },
    checkOneTimeCode(){
      const url = `${config.apiUrl}/api/noauth/carrier/confirmcode`
      axios.post(url,
          {otc: this.one_time_code,
            email: this.otc_email})
          .then((response) => {
            if (response.data.message === 'success') {
              this.show_code_modal = false
              this.show_sw_btn = false
              this.form_step = 5
              this.email_sent = true
              this.email_sent_text = 'Verification passed with success'
            } else if (response.data.message === 'error') {
              this.carrierNewErrorText = 'One time code is incorrect'
              this.carrierNewError = true
            }
          })
    },
    searchByMC() {
      const mc = this.formData.general_info.mc
      this.findBySW(mc)
    },
    async sendOneTimeCode() {
      let url = `${config.apiUrl}/api/noauth/carrier/onetimecode`
      await axios.post(url, {email: this.otc_email}).then((response) => {
        const email = response.data.email
        const otc = response.data.otc

        url = `${config.apiUrl}/api/noauth/carrier/sendcode`
        axios.post(url, {email: email, otc: otc})
            .then((response) => {
              this.email_sent_text = response.data.text
              this.email_sent = true
            })
      })
    },
    async findBySW(value) {
      this.isLoading = true
      const url = `${config.apiUrl}/api/noauth/counterparty/carrier/saferwatch?identity=`
      await axios.post(url + "MC" + value).then(
          (response) => {
            const data = response.data;
            this.otc_email = this.formData.general_info.email
            data.general_info.second_email = this.otc_email
            const validate_data = {
              general_info: data.general_info,
              insurances: {items: data.insurances.items},
              counterparty_addresses: data.counterparty_addresses
            }
            const url = `${config.apiUrl}/api/noauth/carrier/validate`
            axios.post(url, {
              ...validate_data
            }).then((response) => {
              if (response.status === 200) {

                if (data.general_info.email === '')
                  data.general_info.email = this.otc_email

                this.formData.general_info = data.general_info
                this.formData.insurances.items = data.insurances.items
                this.formData.counterparty_addresses = data.counterparty_addresses

                const parsed_file = JSON.stringify(data.insurances.file)
                this.files.insurance_certificate = new File(
                    [parsed_file],
                    'certificate.pdf',
                    {type: 'application/pdf'}
                );
                this.sendOneTimeCode()
                this.disabled_fields = true
                this.sw_status = true
                this.show_code_modal = true
                this.isLoading = false
              }
            }).catch((error) => {
              if (error.code === "ERR_BAD_REQUEST") {
                this.carrierNewErrorText = error.response.data.message
                this.carrierNewError = true
                this.isLoading = false
              }
            })
          }
      )
    },
    handleLocalInCanada() {
      this.localInCanada = !this.localInCanada
      if (this.localInCanada) {
        this.allowedCountries = ['ca']
        this.formData.carrier_data.mc_ff_type = 'cbi'
        this.formData.carrier_data.mc_ff_value = this.formData.carrier_data.cbi_value
        this.formData.carrier_data.dot = null
      } else {
        this.allowedCountries = ['ca', 'us']
      }
    },
    addNewCarrier() {
      const form_inputs = this.$refs.newCarrierForm.inputs

      if (!this.$refs.newCarrierForm.validate()) {
        const inputs_height = []
        const inputs_errors = []
        for (let i = 0; i < form_inputs.length; i++) {
          if (form_inputs[i].errorBucket.length > 0) {
            inputs_height.push(form_inputs[i].$el.clientHeight)
            inputs_errors.push(form_inputs[i].errorBucket)
          }
        }

        if (inputs_errors.length > 0) {
          this.carrierNewErrorText = 'Please fill all required fields with correct data'
          this.carrierNewError = true
        }

        inputs_height.sort((a, b) => a - b);
        let minValue = Math.min(...inputs_height);
        window.scrollTo(0, minValue);

        return false;
      }

      if (this.files.signature === null || !this.isSigned) {
        this.validateSignature = true;
        return false;
      }

      this.isLoading = true
      const prepared_form_data = this.prepareFormDataForSave(this.formData)
      axios.post(config.apiUrl + '/api/noauth/counterparty/carrier-new', prepared_form_data)
          .then((response) => {
            const carrierId = response.data
            return this.uploadFiles(carrierId).then(() => {
              this.isFormSubmitted = true
              this.isLoading = false
              window.scrollTo({
                top: 0, left: 0, behavior: 'smooth'
              })
            })
          })
          .then(() => {
            this.isFormSubmitted = true
            this.isLoading = false
          })
          .catch((error) => {
            if (error.response.data.errors !== undefined) {
              const errors = error.response.data.errors
              this.carrierNewErrorText = Object.values(errors).flatMap(errorList => errorList).join('<br>')
            }
            this.carrierNewError = true
            this.isLoading = false
          })
    },
    async generateSignatureFile(data) {
      const base64Response = await fetch(data)
      const blob = await base64Response.blob()
      this.files.signature = new File([blob], 'Signature.png', {type: 'image/png'})
      this.isSigned = true
    },
    getFilesFormDatas(carrierId) {
      const filesFormDatas = {}
      for (const file_type in this.files) {
        if (file_type !== 'signature') {
          let formData = new FormData()
          formData.append('file_type', file_type)
          formData.append('files[]', this.files[file_type])
          formData.append('entity_id', carrierId)
          formData.append('entity_type', 'counterparty')
          filesFormDatas[file_type] = formData
        }
      }
      return filesFormDatas
    },
    uploadFiles(carrierId) {
      const uploadPromises = []
      for (const formData of Object.values(this.getFilesFormDatas(carrierId))) {
        uploadPromises.push(
            axios.post(config.apiUrl + '/api/file/upload', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
        )
      }

      // Send Agreement and signature
      let formDataAgreement = new FormData()
      formDataAgreement.append('entity_id', carrierId)
      formDataAgreement.append('entity_type', 'counterparty')
      formDataAgreement.append('signature', this.files.signature)
      formDataAgreement.append('agreement', this.agreementFile)
      uploadPromises.push(
          axios.post(config.apiUrl + '/api/counterparty/carrier-new-agreement/', formDataAgreement, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
      )

      return Promise.all(uploadPromises)
    },
    updateAddress(addressObject, addressData, components) {
      let addrNumber = ''
      let addrStreet = ''
      components['address_components'].forEach((item) => {
        if (item.types.includes('street_number')) {
          addrNumber = item['long_name']
        } else if (item.types.includes('route')) {
          addrStreet = item['long_name']
        } else if (item.types.includes('locality')) {
          addressObject.city = item['long_name']
        } else if (item.types.includes('administrative_area_level_1')) {
          addressObject.state = item['short_name'].toUpperCase()
        } else if (item.types.includes('country')) {
          addressObject.country_code = item['short_name'].toUpperCase()
        } else if (item.types.includes('postal_code')) {
          addressObject.postcode = item['short_name'].toUpperCase()
        }
      })

      addressObject.address1 = (addrNumber ? (addrNumber + ' ') : '') + addrStreet
      addressObject.location_coordinates = {
        type: 'Point',
        coordinates: [
          addressData.longitude,
          addressData.latitude,
        ]
      }

      delete addressObject.photos

      addressObject.location_data = components

      if (Object.keys(this.addressW89).length === 0) {
        this.addressEntityW89.location = components['formatted_address']
        this.addressW89 = {...this.addressBilling}
      }
      this.formData.counterparty_addresses = [this.addressBilling, this.addressW89];
    }
  }
}

</script>

<style lang="scss">
.carrier_new_wrapper {
  height: 250vh;
}

@keyframes pulsate {
  0% {
    box-shadow: 0 0 3px 1px rgba(40, 167, 69, 0.8);
  }
  50% {
    box-shadow: 0 1px 5px 2px rgba(40, 171, 70, 0.8);
  }
  100% {
    box-shadow: 0 2px 8px 3px rgba(47, 126, 64, 0.8);
  }
}

.pulsating {
  animation: pulsate 2s infinite;
}

</style>